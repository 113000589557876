
/******************
* MODULY
******************/

.uvodVsuvkaObal{
	background: #ededed;
	padding: 36px 0;
}

#uvodtitul {
	background: white;
	overflow: hidden;
	box-shadow: 0 0 46px rgba(#000000, .1);
	max-width: 1130px;
	margin: 0 auto;
	color: #4e4e4e;
	padding: 50px 20px;
	font-size: rem(16px);
	text-align: center;

	.editorUzsi{
		max-width: 750px;
		margin: 0 auto;
	}

	h2, h3, h4{
		font-size: rem(28px);
		color: #252525;
		padding: 0 0 16px;
		font-weight: bold;
	}

	h3{
		font-size: rem(25px);
	}

	h4{
		font-size: rem(22px);
	}
}

#obalUpozorneni{
	@include bp("> 600px"){
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 5;

		#upozorneni{
			min-height: 88px;
		}
	}
}

#upozorneni {
	box-sizing: border-box;
	padding: 10px 16px;
	background-color: rgba(#000000, .6);
	font-size: rem(17px);
	display: flex;
    width: 100%;
	justify-content: center;

	&,
	h2.cvi,
	#{selector-link()} {
		color: #ffffff;
	}

	p {		
		align-self: center;
		line-height: 1.2;
	}

	.vicedok{
		text-transform: uppercase;
		display: inline-block;
		text-decoration: none;
		border-bottom: 1px solid rgba(#ffffff, .55);

		&:hover{
			border-bottom-color: transparent;
		}
	}
	
	.volAdmin{
		position: absolute;
		top: -55px;
	}
}

.banneryFoto{
	.banner{
		border: 10px solid white;
		box-sizing: border-box;
		position: relative;
		width: 100%;
		margin: 0 auto;
		max-width: 288px;
	}

	a{
		font-size: rem(20px);
		font-weight: 400;
		color: #252525;
		line-height: 1.2;
		border: 1px solid #c8c8c8;
		@include fill;

		&:hover{
			background-color: #ededed;

			> span{
				background-size: auto 115%;
				
				&::before{
					transform: scale(1) translate3d(0,0,0);
				}
			}

			strong{
				text-decoration: underline;
			}
		}

		> span{
			overflow: hidden;
			bottom: 17.2%;
			left: -1px; right: -1px; top: -1px;
			position: absolute;
			
			&::before {
				content: "";
				position: absolute;
				left: -15%;
				top: -15%;
				bottom: -15%;
				right: -15%;
				background-color: $barL;
				transform: scale(.85) translate3d(0,0,0);
				transition: transform .75s cubic-bezier(.35,.09,.3,.97);
				background: {
					size: auto 100%;
					repeat: no-repeat;
					position: 50% 50%;
				}
			}
		}
	}

	#banner-levyHorni a > span::before {background-image: url(images/bannery/codelat.jpg);}
	#banner-pravyHorni a > span::before {background-image: url(images/bannery/ubytovani.jpg);}
	#banner-levyStred a > span::before {background-image: url(images/bannery/novinky.jpg);}
	#banner-pravyStred a > span::before {background-image: url(images/bannery/pamatky.jpg);}
	#banner-levyDolni a > span::before {background-image: url(images/bannery/prohlidky.jpg);}
	#banner-pravyDolni a > span::before {background-image: url(images/bannery/muzeum.jpg);}
	
	strong{
		position: absolute;
		left: 0;
		bottom: 3%;
		width: 100%;
		display: block;
		text-align: center;
		font-weight: normal;

		@include bp("> 1000px", "<= 1200px"){
			bottom: 1.5%;
		}

	}

	.banner1-3{
		padding-bottom: 295px;
	} 
	
	.banner1-2{
		padding-bottom: 210px;
	}
	
	.banner2-3{
		padding-bottom: 218px;
	}

	@include bp("> 500px"){
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
	
		$gap: ratio(748px, 20px);
		$sc: 3.8*$gap;
	
		$w1-3: 3*$sc + 2*$gap;
		$w1-2: 4*$sc + 3*$gap;
		$w2-3: 5*$sc + 4*$gap;
		
		.banner{
			margin: 0;
			max-width: 100%;
			border: 0;
		}
	
		.row{
			display: flex;
			justify-content: space-between;
			margin-bottom: 2%;
		}
	
		.banner1-3{
			width: $w1-3;
			padding-bottom: 42%;
		} 
		
		.banner1-2{
			width: $w1-2;
			padding-bottom: 42%;
		}
		
		.banner2-3{
			width: $w2-3;
		}
		
	}

	@include bp("> 1400px"){
		a{
			font-size: rem(23px);
		}
	}	
}

@include edge-only {
	.banneryFoto{
		.row{
			height: 32%;
		}
	}
}	

#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

#vypisakci{
	.nadpisObal{
		background: url(images/bgKa.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 20% 5% 1.3%;
		box-sizing: border-box;
	}

	h2.cvi{
		font-size: rem(40px);
		font-weight: bold;
		text-transform: uppercase;
		color: white;

		@include bp("> 600px"){
			font-size: rem(46px);
		}
	}
}

#obalmbannery{
	min-width: 320px;
	background: #ffffff;
}

#mbannery {
	padding: 30px 0;
	color: #000000;
	margin: 0;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

#jmeniny {
	padding: 6px 12px 2px 15px;
	text-align: left;
	
	* {
		display: inline;
	}
}

#kontakt {
	*{
		box-sizing: border-box;
	}

	.adresa{
		display: block;
	}

	.webove-odkazy{
		display: block;
		padding-top: 2px;
		line-height: 2;
	}

	@include bp("> 600px"){
		.adresa{
			float: left;
			width: ratio(600px, 280px);
			padding-right: 3%;
		}
	
		.navstevniDoba{
			float: left;
			box-sizing: border-box;
			padding-right: 20px;
			width: ratio(600px, 320px);
		}
	}

	@include bp("> 1000px"){
		.kontaktUzsi{
			max-width: 600px;
			width: 100%;
			border-right: 2px solid white;
		}
	}

	address {
		display: block;
		font-style: normal;
		line-height: 1.5;

		.telefon{
			display: inline-block;
			margin-top: 15px;
		}
	}
	
	strong {
		font-weight: bold;
		display: inline-block;
		padding: 4px 0;
	}

	.socSite{
		display: block;
		margin: 28px 0 46px;
				
		img{
			margin-right: 16px;
		}

		@include bp("> 600px"){
			display: inline-block;
			margin: 18px 0 3px 30px;
			vertical-align: bottom;
	
		}

		@include bp("> 1000px", "< 1200px"){
			margin: 18px 0 3px 10px;
			
			img{
				margin-right: 6px;
			}
		}

		@include bp("> 1400px"){
			margin-left: 104px;
		}	
	}

	.dalsi{
		text-align: left;
		padding: 24px 0 0;
		clear: both;
		margin: 0;

		a{
			display: inline-block;
			box-sizing: border-box;
			padding: 13px 24px;
			font-size: rem(17px);
			color: #101010;
			background: $barL;
			text-align: center;
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	.obalSite{
		clear: both;
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}

	@include bp("< 600px"){
		li {
			display:block!important;
	
			+ li::before{
				content: "";
				margin:0;
			}	
		}
	}
}

.tajemstvi{
    &__obal{
        position: relative;
    }
    &__img{

    }
    &__list{
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        margin: 0;
        list-style-type: none;
        box-sizing: border-box;
        font-family: $font-medieval;
    }
    &__item{
        position: absolute;
        margin: 0;
        width: 5%;
        transform: translate(-50%, -50%);
        &:nth-child(1){
            top: 27.5%;
            left: 42%;
        }
        &:nth-child(2){
            top: 31.7%;
            left: 48%;
        }
        &:nth-child(3){
            top: 29%;
            left: 64.3%;
        }
        &:nth-child(4){
            top: 34.2%;
            left: 75.9%;
            .tajemstvi__description{
                left: auto;
                transform: translateX(0);
                right: -1.5rem;
                &::before{
                    left: auto;
                    right: 0;
                }
            }
        }
        &:nth-child(5){
            top: 39.5%;
            left: 73%;
        }

        &:nth-child(6){
            top: 48.8%;
            left: 85.4%;
            .tajemstvi__description{
                left: auto;
                transform: translateX(0);
                right: -1.5rem;
                &::before{
                    left: auto;
                    right: 0;
                }
            }
        }
        &:nth-child(7){
            top: 54.5%;
            left: 83.5%;
            .tajemstvi__description{
                left: auto;
                transform: translateX(0);
                right: -1.5rem;
                &::before{
                    left: auto;
                    right: 0;
                }
            }
        }
    }
    &__link{
        width: 100%;
        padding: 0 0 100%;
        height: 0;
        display: inline-block;
        background: transparent;
        border: 0;
        cursor: pointer;
        //background-color: red;
        &:focus{
            & + p{
                display: inline-block;
            }
        }
    }
    &__description{
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        box-sizing: border-box;
        display: none;
        margin: 0;
        background-color: #fff;
        width: 14em;
        padding: 1rem 1.5rem;
        border-radius: 5px;
        box-shadow: 2px 2px 10px;
        &::before{
            position: absolute;
            content: "";
            bottom: calc(100% - 2px);
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 14px 20px;
            border-color: transparent transparent #ffffff transparent;
        }
        @include link-over(&){
            &{
                display: inline-block;
            }
        }
        @include min(900px){
            width: 18em;
            font-size: rem(18px);
        }
        @include min(1280px){
            width: 24em;
        }
        strong{
            margin-top: .5rem;
            display: block;
        }
    }
    @include link(){

    }
}