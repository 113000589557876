
/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: $barL;
	color: #101010;

	@include link {
		color: #101010;
	}

	.centrovany {
		position: relative;
	}
} 

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
}

#znak {
	margin: 0;
}

.nazevObal{
	box-sizing: border-box;
	padding: 58px 0 20px;

	@include bp("> 600px"){
		padding: 48px 0 10px; 
	}

	@include bp("> 1024px"){
		padding: 9px 260px 9px 160px; 
	}

	@include bp("> 1200px"){
		padding: 9px 260px; 
	}
}

#nazev {
	text-align: center;
	position: relative;
    max-width: 520px;
    margin: 0 auto;
    width: 100%;

	a {
		z-index: 30;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		&::after {
			content: "";
			@extend %fill;
		}
	}

	img {
		display: none;
		font-size: rem(16px);
		line-height: 1;
		vertical-align: middle;

		@include bp("> 600px"){
			display: inline-block;
		}
	}
}

.listaVpravo{
	position: absolute;
	right: 0;
	top: 9px;
	width: 100%;

	@include bp("> 600px"){
		width: auto;
	}
	
	a{
		text-decoration: none!important;

		&:hover{
			text-decoration: underline!important;
		}
	}

	.proObcany{
		display:inline-block;
		margin-right: 11px;

		img{
			margin-left: 10px;
			position: relative;
			top: 2px;
		}
	}
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek {
	clear: both;
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
}

#titul #obrazek, #stranka #obrazek {
	display: none;
	background-repeat: no-repeat;
	background-position: center top;
	position: relative;
}

@include bp("> 600px") {
	#titul #obrazek {
		display: block;
		background-size: cover;
	}

	#stranka #obrazek{
		display: block;
		background-image: url(images/vyrez.jpg);
		padding-bottom: 255px; //255px
		background-size: cover;
        @include min(1320px) {
            padding-bottom: 19.5%;
        }
	}

	#administraceobrazky {
		position: absolute;
		left: 50%;
		transform: translate(-50%,-50%);
		bottom: 50%;
	 	z-index: 16;
	}

	#obrlinky {
		position: absolute;
		z-index: 2;
		bottom: 14px;
		right: 25px;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				display: inline;
				padding: 0;
				margin: 0 0 0 14px;
			}
		}
	}
}

#blender {
	height: 0;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
	background-size: cover;

	@include bp("> 600px") {
		// padding-bottom: ratio(1535px, 695px);
		padding-bottom: ratio(1920px, 600px);
	}

	// @include bp("> 1535px"){
	// 	padding-bottom: 695px;
	// }
}

.blender__buttons {
	position: absolute;
	right: 1em;
	bottom: 1em;
}

.blender__button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent none;
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}
}


@keyframes blend-in {
	from {
		display: block;
		opacity: 0;
	  } to {
	  display: block;
		opacity: 1;
	}
}

.blender__item {
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: cover;
	}
}

.blender__item {
	@include fill;
}

.blender__item.blender__item--animating {
	animation: blend-in 800ms ease-out;
	animation-fill-mode: forwards;
}



#lista {
	@extend %sf;
	text-align: right;
	padding: 0;
	position: relative;

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
