@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
@import url("https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap");
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1 ul, #menu ul, body, .obrodkazy li, #hledani .btn, #keskryti, #titul .zalozky li, #stranka #kalakci .mesice {
  margin: 0;
  padding: 0;
}

#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1 ul, #menu ul {
  list-style-type: none;
}

#nazev a::after, .titulformular label.label-skryty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#kalakci .calcells td .desc {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

#stred,
#vpravo,
#vlevo, .odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.centrovany::after, #hlava::after, #lista::after, .zalozky ul::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** Hodnoty je lepší zadávat v em **/
/** MENU **/
/** ZÁLOŽKY **/
/** PATA **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
html {
  line-height: 1.375;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  line-height: 1.2;
}

body,
.editor,
body.editor,
body.editor td,
body.editor th {
  font-size: 1em;
}

body,
.automat {
  color: #444444;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1.25rem;
  padding: 2px 0 0 0;
  line-height: 1.1;
  margin: 0;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #252525;
  display: inline-block;
  vertical-align: middle;
}

h1.cvi strong {
  font-size: 1.625rem;
  font-weight: bold;
  display: block;
  text-transform: uppercase;
  margin: 0 5px 0 8px;
}

@media screen and (min-width: 601px) {
  h1.cvi strong {
    display: inline-block;
  }
}

#stranka h1.cvi strong {
  margin: 0 9px 0 8px;
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
}

h2.cvi,
.poz h2.cist {
  font-size: 2.125rem;
  color: #367b0f;
  font-weight: normal;
  margin: 0;
  padding: 8px 0;
  line-height: 1.2;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  margin: 45px 0 30px 0;
  padding: 0;
  font-size: 1.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

@media screen and (min-width: 1101px) {
  #zahlavi h2.cvi,
  #zalozkynadpis h2.cvi {
    font-size: 2.125rem;
  }
}

.editor h3, h3.cvi, h3.ud,
#prohlaseni h3 {
  font-size: 1.75rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.3125rem;
  line-height: 1.2;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: .75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
}

h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}

@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  box-sizing: border-box;
  padding: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: lightgrey;
}

@media screen and (max-width: 499px) {
  #pozicovani .cards li {
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 1023px) {
  #pozicovani .cards li {
    width: calc( 50% - 10px);
    margin-left: 20px;
  }
  #pozicovani .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  #pozicovani .cards li {
    width: calc( 33.33333% - 13.33333px);
    margin-left: 20px;
  }
  #pozicovani .cards li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

#pozicovani .cards li img {
  float: none;
  display: table;
  margin: 0;
  width: 100%;
}

#pozicovani .cards li .dok-datum {
  margin-top: 10px;
}

#pozicovani .cards li .dok-upoutavka {
  display: block;
  overflow: hidden;
  margin: 0;
}

#pozicovani .cards li .dok-upoutavka img {
  margin-bottom: 10px;
}

#pozicovani .cards li .dok-nazev {
  display: block;
}

#pozicovani .cards li div {
  margin-top: 8px;
}

/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

::-moz-selection {
  background-color: #228be6;
  color: #ffffff;
}

::selection {
  background-color: #228be6;
  color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

img {
  color: inherit;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 94%;
  max-width: 1535px;
  margin: 0 auto;
  text-align: left;
}

#hlobsah, #navigace, #navigace2 {
  display: block;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
}

@media screen and (min-width: 801px) {
  #stranka #stred, #stranka
  #vpravo, #stranka
  #vlevo {
    margin-bottom: 48px;
  }
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

.stredUzsi {
  width: 100%;
  max-width: 750px;
  font-size: 1.0625rem;
}

@media screen and (min-width: 801px) {
  .stredUzsi {
    float: left;
  }
}

#titul #telo {
  margin: 55px auto 44px;
  display: block;
}

#titul #vlevo {
  position: relative;
  max-width: 748px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 36px;
}

@media screen and (min-width: 501px) {
  #titul #vlevo {
    padding-bottom: 700px;
  }
}

@media screen and (min-width: 601px) {
  #titul #vlevo {
    padding-bottom: 138%;
  }
}

@media screen and (min-width: 801px) {
  #titul #vlevo {
    padding-bottom: 1020px;
  }
}

@media screen and (min-width: 1001px) {
  #titul #telo {
    margin: 77px auto 66px;
  }
  #titul #vlevo {
    width: 48.72964%;
    padding-bottom: 64%;
    float: left;
  }
  #titul #vpravo {
    width: 48.72964%;
    float: right;
  }
}

#stranka #obalcelek {
  max-width: 1920px;
  margin: 0 auto;
}

#stranka #stred {
  padding-bottom: 40px;
}

@media screen and (min-width: 801px) {
  #stranka #obalcelek {
    overflow: hidden;
  }
  #stranka #stred {
    padding: 15px 0 15px 6%;
  }
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
@media screen and (min-width: 801px) {
  #stranka #telo {
    display: flex;
    justify-content: space-between;
  }
  #stranka #stred {
    width: 68.72964%;
    order: 1;
    background-color: #ffffff;
  }
  #stranka #vlevo {
    width: 31.27036%;
    position: relative;
    background: #367b0f;
  }
  #stranka #vlevo::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 46px;
    background: #367b0f;
  }
}

/************************
* PRVKY STRÁNKY
************************/
.cestaObal {
  background-color: #ededed;
  min-height: 71px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
}

.cesta {
  font-size: 1rem;
  padding: 8px 0 8px 0;
  position: relative;
  color: #595959;
  align-self: center;
  text-align: right;
}

.cesta a {
  color: #595959;
}

.cesta strong {
  display: none;
  text-transform: uppercase;
  font-weight: normal;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.dalsi {
  font-size: 1.125rem;
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: center;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
}

.dalsi a {
  display: block;
  padding: 10px 30px;
  background-color: blueviolet;
}

.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  color: #ffffff;
  text-decoration: none;
}

.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}

@media screen and (min-width: 801px) {
  .dalsi {
    text-align: right;
  }
  .dalsi a {
    display: inline-block;
  }
}

.nadpatou2 {
  background-color: #ededed;
  padding: 0 0 40px;
}

.nadpatou2 h2.cvi {
  color: #367b0f;
  font-size: 1.875rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 18px 0;
  margin-top: 24px;
}

.nadpatou2 h2.cvi::after {
  content: "";
  width: 60px;
  height: 60px;
  background-color: #367b0f;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 60px;
  margin-left: 7%;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 499px) {
  .nadpatou2 h2.cvi {
    font-size: 1.5625rem;
  }
  .nadpatou2 h2.cvi::after {
    margin-left: 3%;
    width: 52px;
    height: 52px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .nadpatou2 h2.cvi {
    font-size: 1.5625rem;
  }
  .nadpatou2 h2.cvi::after {
    margin-left: 3%;
    width: 52px;
    height: 52px;
  }
}

@media screen and (min-width: 1201px) {
  .nadpatou2 {
    padding: 16px 0 54px;
  }
}

.nadpatou2 .mapa-slany h2.cvi::after {
  background-image: url(images/mapa_ico.png);
}

.nadpatou2 #napiste h2.cvi::after {
  background-image: url(images/napiste.png);
}

.nadpatou2 #abo h2.cvi::after {
  background-image: url(images/abo.png);
}

.nadpatou2 .boxNp2 {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 900px) {
  .nadpatou2 .boxNp2 {
    width: 100%;
  }
}

@media screen and (min-width: 901px) {
  .nadpatou2 .boxNp2 {
    width: 31.33333%;
    margin-left: 3%;
  }
  .nadpatou2 .boxNp2:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (max-width: 900px) {
  .nadpatou2 .boxNp2 {
    float: none;
    max-width: 460px;
    margin: 0 auto;
  }
}

.nadpatou2 .poradna p {
  color: #595959;
  font-size: 1.0625rem;
  max-width: 280px;
  margin: 0 0 18px;
}

.nadpatou2 .poradna a {
  display: inline-block;
  width: 178px;
  box-sizing: border-box;
  padding: 13px 0;
  font-size: 1.0625rem;
  color: #101010;
  background: #7ec656;
  text-align: center;
  text-decoration: none;
}

.nadpatou2 .poradna a:hover {
  text-decoration: underline;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  background: #367b0f;
  padding: 20px 0 40px;
}

#nadpatou,
#nadpatou h2.cvi,
#nadpatou h3.cvi,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #ffffff;
}

#nadpatou h2.cvi {
  font-size: 1.75rem;
  color: white;
  text-transform: uppercase;
  padding: 0 0 10px;
  margin: 40px 0 0;
}

@media screen and (min-width: 601px) {
  #nadpatou .boxNp {
    float: left;
    width: 47%;
    padding-right: 3%;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1001px) {
  #nadpatou {
    padding: 60px 0;
  }
  #nadpatou h2.cvi {
    margin: 0;
    padding: 0 0 20px;
  }
  #nadpatou .kontaktNp {
    float: left;
    width: 43.32248%;
  }
  #nadpatou .boxNp {
    float: left;
    width: 28.33876%;
    padding: 0 16px 0 2.5%;
  }
}

#obalpata {
  background-color: #7ec656;
  min-width: 320px;
  font-family: Arial, Helvetica, sans-serif;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #101010;
  box-sizing: border-box;
  font-size: 0.875rem;
}

#pata * {
  line-height: 2.2;
  border: 0;
}

#pata li + li::before {
  content: "\|";
  margin: 0 8px 0 0;
}

#pata .partWebmaster1::before {
  content: "\|";
  margin: 0 10px 0 0;
}

#pata a,
#pata a:link,
#pata a:visited {
  color: #101010;
  text-decoration: underline;
}

#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}

#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}

#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}

#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}

#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}

#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}

#pata .webmaster {
  display: block;
}

#stranka #pata .webmaster {
  margin-top: 2px;
}

#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}

#pata ul.inline li {
  display: inline-block;
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}

#unpobtekane {
  margin-left: 0 !important;
}

#nahoru {
  transition: opacity 0.3s;
  position: fixed;
  left: 13px;
  bottom: 40px;
  z-index: 50;
  opacity: 0;
}

#kalendarAkci.obsah .dok li.u {
  margin: 0;
  padding: 16px 0;
}

#kalendarAkci.obsah .dok li.u strong .upoutavka {
  max-width: 180px;
  max-height: 135px;
  overflow: hidden;
  display: block;
  float: left;
  padding-right: 20px;
}

#kalendarAkci.obsah .dok li.u strong .upoutavka img {
  max-width: 100%;
  margin: 0 0 6px;
}

@media screen and (min-width: 601px) {
  #kalendarAkci.obsah .dok li.u strong .upoutavka {
    max-width: 300px;
    max-height: 225px;
  }
}

span.zodpovida {
  display: none;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #7ec656;
  color: #101010;
}

#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #101010;
}

#obalhlava .centrovany {
  position: relative;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
}

#znak {
  margin: 0;
}

.nazevObal {
  box-sizing: border-box;
  padding: 58px 0 20px;
}

@media screen and (min-width: 601px) {
  .nazevObal {
    padding: 48px 0 10px;
  }
}

@media screen and (min-width: 1025px) {
  .nazevObal {
    padding: 9px 260px 9px 160px;
  }
}

@media screen and (min-width: 1201px) {
  .nazevObal {
    padding: 9px 260px;
  }
}

#nazev {
  text-align: center;
  position: relative;
  max-width: 520px;
  margin: 0 auto;
  width: 100%;
}

#nazev a {
  z-index: 30;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}

#nazev a::after {
  content: "";
}

#nazev img {
  display: none;
  font-size: 1rem;
  line-height: 1;
  vertical-align: middle;
}

@media screen and (min-width: 601px) {
  #nazev img {
    display: inline-block;
  }
}

.listaVpravo {
  position: absolute;
  right: 0;
  top: 9px;
  width: 100%;
}

@media screen and (min-width: 601px) {
  .listaVpravo {
    width: auto;
  }
}

.listaVpravo a {
  text-decoration: none !important;
}

.listaVpravo a:hover {
  text-decoration: underline !important;
}

.listaVpravo .proObcany {
  display: inline-block;
  margin-right: 11px;
}

.listaVpravo .proObcany img {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  clear: both;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

#titul #obrazek, #stranka #obrazek {
  display: none;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
}

@media screen and (min-width: 601px) {
  #titul #obrazek {
    display: block;
    background-size: cover;
  }
  #stranka #obrazek {
    display: block;
    background-image: url(images/vyrez.jpg);
    padding-bottom: 255px;
    background-size: cover;
  }
}

@media screen and (min-width: 601px) and (min-width: 1320px) {
  #stranka #obrazek {
    padding-bottom: 19.5%;
  }
}

@media screen and (min-width: 601px) {
  #administraceobrazky {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 50%;
    z-index: 16;
  }
  #obrlinky {
    position: absolute;
    z-index: 2;
    bottom: 14px;
    right: 25px;
  }
  #obrlinky ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  #obrlinky ul li {
    display: inline;
    padding: 0;
    margin: 0 0 0 14px;
  }
}

#blender {
  height: 0;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #303030 url(images/blenderImages/0.jpg#u309f4f14) no-repeat 50% 50%;
  background-size: cover;
}

@media screen and (min-width: 601px) {
  #blender {
    padding-bottom: 31.25%;
  }
}

.blender__buttons {
  position: absolute;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}

.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__item.blender__item--animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

#lista {
  text-align: right;
  padding: 0;
  position: relative;
}

#lista ul {
  position: relative;
}

#lista ul.inline {
  display: inline;
}

#lista li {
  margin: 0;
  white-space: nowrap;
}

#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}

#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}

#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23bcbcbc' stroke='%23bcbcbc' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}

li.u, li.typsouboru {
  list-style-type: none !important;
}

li.u::before, li.typsouboru::before {
  content: normal !important;
}

.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #367B0F;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  font-size: 1.4375rem;
}

.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #367B0F;
}

.utvary .ui li > strong:first-child,
.souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited,
.souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}

.ui li > strong:first-child img {
  font-size: 0.875rem;
}

.utvary .ui li li > strong:first-child,
.bodkazy .ui li li > strong:first-child,
.souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}

.ui ul li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}

.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}

dl.kontakty .utvary .ui li strong + div,
#stromutvaru .utvary .ui li strong + div,
.odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}

dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}

.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}

.ui li .ui .ui {
  padding-bottom: 0;
}

.ui li .ui .ui .ui {
  padding-left: 18px;
}

.ui li li {
  margin: 5px 0;
}

.ui li li::after {
  content: normal;
}

.ui ol li {
  padding-left: 0;
}

.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}

dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, Helvetica, sans-serif;
}

dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}

.dok li:first-child {
  margin-top: 8px;
}

.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}

.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}

.dok .vd-priloha {
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
}

.dok strong {
  font-weight: normal;
}

.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}

.dok strong a {
  font-size: 1.375rem;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  color: #367B0F;
  line-height: 1.375;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}

#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
  max-width: 180px;
}

/** DOKUMENTY NA TITULCE **/
#pozicovani {
  padding-bottom: 20px;
}

#pozicovani .poz {
  margin-bottom: 25px;
}

#pozicovani .cards {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}

.obrodkazy li::before {
  content: normal !important;
}

.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}

.obrodkazy strong {
  font-weight: normal;
}

.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}

.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci ul {
  margin: 0;
  padding: 0;
}

#vypisakci li {
  display: flex;
  margin: 12px 0 0;
  padding: 24px 4% 24px 6%;
  width: 100%;
  background-color: #367b0f;
  color: white;
  box-sizing: border-box;
}

#vypisakci li a {
  color: white;
}

#vypisakci li strong a,
#vypisakci li strong a:link,
#vypisakci li strong a:visited {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid #9abc86;
  font-size: 1.25rem;
  line-height: 1.3;
}

#vypisakci li strong a:hover,
#vypisakci li strong a:link:hover,
#vypisakci li strong a:visited:hover {
  border: 0;
}

@media screen and (min-width: 601px) {
  #vypisakci li strong a,
  #vypisakci li strong a:link,
  #vypisakci li strong a:visited {
    font-size: 1.4375rem;
  }
}

#vypisakci .denPredlozka,
#vypisakci .rok,
#vypisakci .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}

#vypisakci .prazdnyDatum {
  background: transparent;
}

#vypisakci .obsahAkce {
  flex-grow: 1;
}

#vypisakci .datum-cislo {
  font-weight: 600;
  font-size: 1.875rem;
  color: white;
}

#vypisakci .datum-cisloR::after {
  content: normal !important;
}

#vypisakci .datum-cislo:not(:empty)::after {
  content: "/";
  font-weight: normal;
  color: #85d957;
}

@media screen and (min-width: 601px) {
  #vypisakci .datum-cislo {
    font-weight: 800;
    font-size: 2.25rem;
  }
}

#vypisakci .typAkce a {
  display: none;
  padding-top: 100px;
  background-position: center top;
  background-repeat: no-repeat;
  color: white;
  text-decoration: underline;
  width: 105px;
  text-align: center;
}

#vypisakci .typAkce a:hover {
  text-decoration: none;
}

#vypisakci .typAkce a:first-child {
  display: block;
}

#vypisakci .typAkce .carka {
  display: none;
}

#vypisakci .typAkce .t1142 {
  background-image: url(images/piktogramy/workshopy.png);
}

#vypisakci .typAkce .t1002 {
  background-image: url(images/piktogramy/sport.png);
}

#vypisakci .typAkce .t1003 {
  background-image: url(images/piktogramy/ostatni.png);
}

#vypisakci .typAkce .t1093 {
  background-image: url(images/piktogramy/deti.png);
}

#vypisakci .typAkce .t1141 {
  background-image: url(images/piktogramy/divadlo.png);
}

#vypisakci .typAkce .t1145 {
  background-image: url(images/piktogramy/film.png);
}

#vypisakci .typAkce .t1144 {
  background-image: url(images/piktogramy/turistika.png);
}

#vypisakci .typAkce .t1146 {
  background-image: url(images/piktogramy/vystavy.png);
}

#vypisakci .typAkce .t1143 {
  background-image: url(images/piktogramy/koncerty.png);
}

#vypisakci .typAkce .t1147 {
  background-image: url(images/piktogramy/kultura.png);
}

#vypisakci .typAkce .t1195 {
  background-image: url(images/piktogramy/prednaska.png);
}

.vsechnyAkce {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.vsechnyAkce a {
  display: inline-block;
  width: 178px;
  box-sizing: border-box;
  padding: 13px 0;
  font-size: 1.0625rem;
  color: #101010;
  background: #7ec656;
  text-align: center;
  text-decoration: none;
  margin: 10px 0 0;
}

.vsechnyAkce a:hover {
  text-decoration: underline;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 8px 0;
  padding: 0;
  position: relative;
}

.titulodkazy li::before {
  content: normal !important;
}

.titulodkazy li a {
  font-size: 1rem;
}

.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #232323;
}

.titulodkazy li div {
  margin: 2px 0 0 0;
}

.titulodkazy strong {
  font-weight: normal;
}

.dok li.urd-line {
  position: relative;
  margin: 0;
  padding: 15px 20px;
}

.dok li.urd-line:nth-child(even) {
  background-color: #ededed;
}

.dok li.urd-line::after {
  content: normal;
}

.dok li.urd-line .urd-left {
  position: relative;
}

.dok li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

@media screen and (min-width: 801px) {
  .dok li.urd-line {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .dok li.urd-line .urd-left,
  .dok li.urd-line .urd-right {
    display: table-cell;
  }
  .dok li.urd-line .urd-left {
    padding-right: 1.5em;
  }
  .dok li.urd-line .urd-right {
    width: 13em;
  }
}

.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited {
  color: #367b0f;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}

.titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}

.titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #595959;
  height: 44px;
  border-radius: 0;
  line-height: 1.21429;
  font-size: 0.9375rem;
}

.format:-ms-input-placeholder {
  color: inherit !important;
}

.format::-ms-input-placeholder {
  color: inherit !important;
}

.format::placeholder {
  color: inherit !important;
  opacity: 1;
}

.format:focus {
  border-color: #437DCC;
}

.format,
fieldset {
  border: 1px #cccccc solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 88px;
}

select.format[multiple] {
  height: 132px;
}

.btn {
  background-color: #367b0f;
  border: 0;
  color: #ffffff;
  height: 44px;
}

.btn:hover {
  text-decoration: underline;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

/************************************************/
.fkont {
  margin-top: 20px;
}

.fkont form {
  padding: 21px 36px 23px 36px;
}

.fkont :not(.fvlevo) > .fvyraz label[for="hv"] {
  font-size: 1rem;
}

@media screen and (max-width: 580px) {
  .nizky form {
    padding-bottom: 60px;
  }
}

.formular,
.fkont form,
.nastaveni {
  background-color: #eeeeee;
  color: #202020;
}

.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #367b0f;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label {
  display: none;
}

.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.svg) repeat 0 0;
}

.titulformular .format {
  margin: 4px 0;
  padding: 5px 15px;
  width: 100%;
  max-width: 450px;
  font-size: 1.0625rem;
  border-color: #ffffff;
  color: #595959;
  height: 48px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

@media screen and (min-width: 901px) {
  .titulformular .format {
    max-width: 336px;
  }
}

.titulformular textarea {
  height: 68px;
}

.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}

.titulformular .opiste {
  position: relative;
}

.titulformular .captchaformat {
  padding-right: 90px;
}

.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
}

.titulformular .btn {
  display: inline-block;
  width: 178px;
  box-sizing: border-box;
  padding: 10px 0;
  font-size: 1.0625rem;
  color: #101010;
  background: #7ec656;
  text-align: center;
  text-decoration: none;
  border: 0;
  height: 49px;
}

.titulformular .btn:hover {
  text-decoration: underline;
}

.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
  color: #595959;
  font-size: 1.0625rem;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  overflow: hidden;
  margin: 0 0 0 4px;
  float: right;
  width: 134px;
}

#hledani label {
  display: none;
}

#hledani .pole {
  position: relative;
  z-index: 5;
}

#hledani input:focus {
  outline: 0;
}

#hledani .format {
  width: 100%;
  margin: 0;
  height: 36px;
  border-color: #ffffff;
  color: #000000;
  padding: 2px 30px 2px 2px !important;
  background-repeat: no-repeat;
  background-position: 0 50%;
  border: 1px solid #00911A !important;
}

#hledani .format:-ms-input-placeholder {
  color: transparent !important;
}

#hledani .format::-ms-input-placeholder {
  color: transparent !important;
}

#hledani .format::placeholder {
  color: transparent !important;
}

#hledani .btn {
  border: 0;
  position: absolute;
  z-index: 2;
  top: 7px;
  right: 7px;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #000000;
}

.inline-form {
  padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
  display: inline-block;
}

.inline-form-search {
  position: relative;
}

.inline-form-search input,
.inline-form-search label,
.inline-form-search textarea,
.inline-form-search select {
  font-size: 1rem;
}

.inline-form-word {
  margin: .25em 0;
}

.inline-form-input {
  box-sizing: border-box;
  max-width: 12em;
  padding: 2px 10px;
}

.inline-form-datepicker {
  padding-right: 30px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-position: 98% 48%;
  max-width: 8em;
}

.inline-form-input,
.inline-form-select {
  border-radius: 4px;
  border: 1px #888888 solid;
  height: 1.75em;
}

.inline-form .fbtn {
  margin-bottom: -3em;
}

.inline-form-select {
  max-width: 13em;
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.inline-form #prepinac {
  margin-top: 2em;
}

@media screen and (min-width: 601px) {
  .inline-form #prepinac {
    position: absolute;
    left: 0;
    bottom: -2.3em;
    font-size: 1rem;
  }
}

.fkont.vysoky {
  display: none;
}

.fkont.vysoky.akce24 {
  display: block;
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}

.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}

.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}

.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}

.seznam th {
  font-size: 1.125rem;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  line-height: 1.1;
  font-weight: normal;
  background-color: #367b0f;
  color: #ffffff;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}

.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}

.seznam .b1 td {
  border-color: #ffffff;
}

.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 349px) {
  .nahledy li {
    width: 100%;
  }
}

@media screen and (min-width: 350px) and (max-width: 599px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.nahledy div > a {
  background: #ededed;
  border: 2px transparent solid;
  border-radius: 0px;
  min-height: 200px;
}

.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #367b0f;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  font-weight: normal;
}

.zalozky li {
  padding: 0;
  margin: 0 .5em .5em 0;
  float: left;
  position: relative;
  display: block;
}

.zalozky li.azalozka {
  background: transparent none;
}

.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 10px 36px 12px;
}

.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: transparent;
  color: #202020;
}

.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}

.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: #367b0f;
  color: #ffffff;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.vych_pol strong {
  font-weight: normal;
}

a[id*="k0"] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}

.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.3125rem;
  line-height: 1.1;
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

#titul .zalozky .inline li::before {
  content: normal;
}

#titul .zalozky a {
  padding: 15px;
}

#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: transparent;
  color: #202020;
}

#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}

#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: #367b0f;
  color: #ffffff;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}

/******************
* MODULY
******************/
.uvodVsuvkaObal {
  background: #ededed;
  padding: 36px 0;
}

#uvodtitul {
  background: white;
  overflow: hidden;
  box-shadow: 0 0 46px rgba(0, 0, 0, 0.1);
  max-width: 1130px;
  margin: 0 auto;
  color: #4e4e4e;
  padding: 50px 20px;
  font-size: 1rem;
  text-align: center;
}

#uvodtitul .editorUzsi {
  max-width: 750px;
  margin: 0 auto;
}

#uvodtitul h2, #uvodtitul h3, #uvodtitul h4 {
  font-size: 1.75rem;
  color: #252525;
  padding: 0 0 16px;
  font-weight: bold;
}

#uvodtitul h3 {
  font-size: 1.5625rem;
}

#uvodtitul h4 {
  font-size: 1.375rem;
}

@media screen and (min-width: 601px) {
  #obalUpozorneni {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
  #obalUpozorneni #upozorneni {
    min-height: 88px;
  }
}

#upozorneni {
  box-sizing: border-box;
  padding: 10px 16px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.0625rem;
  display: flex;
  width: 100%;
  justify-content: center;
}

#upozorneni,
#upozorneni h2.cvi,
#upozorneni a, #upozorneni a:link, #upozorneni a:visited {
  color: #ffffff;
}

#upozorneni p {
  align-self: center;
  line-height: 1.2;
}

#upozorneni .vicedok {
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.55);
}

#upozorneni .vicedok:hover {
  border-bottom-color: transparent;
}

#upozorneni .volAdmin {
  position: absolute;
  top: -55px;
}

.banneryFoto .banner {
  border: 10px solid white;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 288px;
}

.banneryFoto a {
  font-size: 1.25rem;
  font-weight: 400;
  color: #252525;
  line-height: 1.2;
  border: 1px solid #c8c8c8;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banneryFoto a:hover {
  background-color: #ededed;
}

.banneryFoto a:hover > span {
  background-size: auto 115%;
}

.banneryFoto a:hover > span::before {
  transform: scale(1) translate3d(0, 0, 0);
}

.banneryFoto a:hover strong {
  text-decoration: underline;
}

.banneryFoto a > span {
  overflow: hidden;
  bottom: 17.2%;
  left: -1px;
  right: -1px;
  top: -1px;
  position: absolute;
}

.banneryFoto a > span::before {
  content: "";
  position: absolute;
  left: -15%;
  top: -15%;
  bottom: -15%;
  right: -15%;
  background-color: #7ec656;
  transform: scale(0.85) translate3d(0, 0, 0);
  transition: transform 0.75s cubic-bezier(0.35, 0.09, 0.3, 0.97);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.banneryFoto #banner-levyHorni a > span::before {
  background-image: url(images/bannery/codelat.jpg);
}

.banneryFoto #banner-pravyHorni a > span::before {
  background-image: url(images/bannery/ubytovani.jpg);
}

.banneryFoto #banner-levyStred a > span::before {
  background-image: url(images/bannery/novinky.jpg);
}

.banneryFoto #banner-pravyStred a > span::before {
  background-image: url(images/bannery/pamatky.jpg);
}

.banneryFoto #banner-levyDolni a > span::before {
  background-image: url(images/bannery/prohlidky.jpg);
}

.banneryFoto #banner-pravyDolni a > span::before {
  background-image: url(images/bannery/muzeum.jpg);
}

.banneryFoto strong {
  position: absolute;
  left: 0;
  bottom: 3%;
  width: 100%;
  display: block;
  text-align: center;
  font-weight: normal;
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .banneryFoto strong {
    bottom: 1.5%;
  }
}

.banneryFoto .banner1-3 {
  padding-bottom: 295px;
}

.banneryFoto .banner1-2 {
  padding-bottom: 210px;
}

.banneryFoto .banner2-3 {
  padding-bottom: 218px;
}

@media screen and (min-width: 501px) {
  .banneryFoto {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .banneryFoto .banner {
    margin: 0;
    max-width: 100%;
    border: 0;
  }
  .banneryFoto .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
  }
  .banneryFoto .banner1-3 {
    width: 35.82888%;
    padding-bottom: 42%;
  }
  .banneryFoto .banner1-2 {
    width: 48.6631%;
    padding-bottom: 42%;
  }
  .banneryFoto .banner2-3 {
    width: 61.49733%;
  }
}

@media screen and (min-width: 1401px) {
  .banneryFoto a {
    font-size: 1.4375rem;
  }
}

@supports (-ms-ime-align: auto) {
  .banneryFoto .row {
    height: 32%;
  }
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#vypisakci .nadpisObal {
  background: url(images/bgKa.jpg) no-repeat center bottom;
  background-size: cover;
  padding: 20% 5% 1.3%;
  box-sizing: border-box;
}

#vypisakci h2.cvi {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

@media screen and (min-width: 601px) {
  #vypisakci h2.cvi {
    font-size: 2.875rem;
  }
}

#obalmbannery {
  min-width: 320px;
  background: #ffffff;
}

#mbannery {
  padding: 30px 0;
  color: #000000;
  margin: 0;
}

#mbannery .editor {
  text-align: center;
}

#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}

#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}

#jmeniny * {
  display: inline;
}

#kontakt * {
  box-sizing: border-box;
}

#kontakt .adresa {
  display: block;
}

#kontakt .webove-odkazy {
  display: block;
  padding-top: 2px;
  line-height: 2;
}

@media screen and (min-width: 601px) {
  #kontakt .adresa {
    float: left;
    width: 46.66667%;
    padding-right: 3%;
  }
  #kontakt .navstevniDoba {
    float: left;
    box-sizing: border-box;
    padding-right: 20px;
    width: 53.33333%;
  }
}

@media screen and (min-width: 1001px) {
  #kontakt .kontaktUzsi {
    max-width: 600px;
    width: 100%;
    border-right: 2px solid white;
  }
}

#kontakt address {
  display: block;
  font-style: normal;
  line-height: 1.5;
}

#kontakt address .telefon {
  display: inline-block;
  margin-top: 15px;
}

#kontakt strong {
  font-weight: bold;
  display: inline-block;
  padding: 4px 0;
}

#kontakt .socSite {
  display: block;
  margin: 28px 0 46px;
}

#kontakt .socSite img {
  margin-right: 16px;
}

@media screen and (min-width: 601px) {
  #kontakt .socSite {
    display: inline-block;
    margin: 18px 0 3px 30px;
    vertical-align: bottom;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1199px) {
  #kontakt .socSite {
    margin: 18px 0 3px 10px;
  }
  #kontakt .socSite img {
    margin-right: 6px;
  }
}

@media screen and (min-width: 1401px) {
  #kontakt .socSite {
    margin-left: 104px;
  }
}

#kontakt .dalsi {
  text-align: left;
  padding: 24px 0 0;
  clear: both;
  margin: 0;
}

#kontakt .dalsi a {
  display: inline-block;
  box-sizing: border-box;
  padding: 13px 24px;
  font-size: 1.0625rem;
  color: #101010;
  background: #7ec656;
  text-align: center;
  text-decoration: none;
}

#kontakt .dalsi a:hover {
  text-decoration: underline;
}

#kontakt .obalSite {
  clear: both;
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}

#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}

#anketa ul li {
  margin-bottom: 8px;
}

#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}

#anketa .hlas div {
  height: 12px;
  background-color: #228be6;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}

#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}

#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}

.tajemstvi__obal {
  position: relative;
}

.tajemstvi__list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-sizing: border-box;
  font-family: "MedievalSharp", cursive;
}

.tajemstvi__item {
  position: absolute;
  margin: 0;
  width: 5%;
  transform: translate(-50%, -50%);
}

.tajemstvi__item:nth-child(1) {
  top: 27.5%;
  left: 42%;
}

.tajemstvi__item:nth-child(2) {
  top: 31.7%;
  left: 48%;
}

.tajemstvi__item:nth-child(3) {
  top: 29%;
  left: 64.3%;
}

.tajemstvi__item:nth-child(4) {
  top: 34.2%;
  left: 75.9%;
}

.tajemstvi__item:nth-child(4) .tajemstvi__description {
  left: auto;
  transform: translateX(0);
  right: -1.5rem;
}

.tajemstvi__item:nth-child(4) .tajemstvi__description::before {
  left: auto;
  right: 0;
}

.tajemstvi__item:nth-child(5) {
  top: 39.5%;
  left: 73%;
}

.tajemstvi__item:nth-child(6) {
  top: 48.8%;
  left: 85.4%;
}

.tajemstvi__item:nth-child(6) .tajemstvi__description {
  left: auto;
  transform: translateX(0);
  right: -1.5rem;
}

.tajemstvi__item:nth-child(6) .tajemstvi__description::before {
  left: auto;
  right: 0;
}

.tajemstvi__item:nth-child(7) {
  top: 54.5%;
  left: 83.5%;
}

.tajemstvi__item:nth-child(7) .tajemstvi__description {
  left: auto;
  transform: translateX(0);
  right: -1.5rem;
}

.tajemstvi__item:nth-child(7) .tajemstvi__description::before {
  left: auto;
  right: 0;
}

.tajemstvi__link {
  width: 100%;
  padding: 0 0 100%;
  height: 0;
  display: inline-block;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.tajemstvi__link:focus + p {
  display: inline-block;
}

.tajemstvi__description {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  display: none;
  margin: 0;
  background-color: #fff;
  width: 14em;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 10px;
}

.tajemstvi__description::before {
  position: absolute;
  content: "";
  bottom: calc(100% - 2px);
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 14px 20px;
  border-color: transparent transparent #ffffff transparent;
}

.tajemstvi__description:hover, .tajemstvi__description:focus, .tajemstvi__description:active {
  display: inline-block;
}

@media screen and (min-width: 900px) {
  .tajemstvi__description {
    width: 18em;
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 1280px) {
  .tajemstvi__description {
    width: 24em;
  }
}

.tajemstvi__description strong {
  margin-top: .5rem;
  display: block;
}

/*******************************
* KALENDÁŘE
*******************************/
#kalendarAkci.obsah .dok strong a {
  color: #367b0f;
}

#kalendarAkci.obsah .datum {
  font-weight: bold;
  font-size: 1.125rem;
}

.mesice {
  display: flex;
  justify-content: space-between;
  color: #2a2a2a;
}

#stranka .mesice .c-actual-month {
  text-align: center;
}

#kalakci {
  max-width: 30em;
  width: 104%;
  margin-left: -2%;
}

@media screen and (min-width: 451px) {
  #kalakci {
    width: 106%;
    margin-left: -3%;
  }
}

#kalakci .calcells {
  width: 100%;
  border-spacing: 0 1.5em;
  font-size: 0.875rem;
}

#stranka #kalakci .calcells {
  border-spacing: 0 1.1em;
}

#kalakci .calcells td,
#kalakci .calcells th {
  box-sizing: border-box;
  position: relative;
}

#kalakci .calcells th {
  top: -6px;
  color: #2a2a2a;
}

#kalakci .calcells td {
  width: 14.28%;
  height: 2em;
  text-align: center;
  font-size: 0.875rem;
  z-index: 0;
}

#kalakci .calcells td::before {
  content: "";
  position: absolute;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 500em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em 0 0 -1em;
  box-sizing: border-box;
  z-index: -1;
}

#kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}

#kalakci .calcells td.wkday, #kalakci .calcells td.wkday a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.curdate::before {
  background-color: magenta;
}

#kalakci .calcells td.curdate, #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#kalakci .calcells td.curdate:hover, #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px blue solid;
}

#kalakci .calcells td.notmnth, #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
}

#kalakci .calcells td a {
  line-height: 2em;
  position: absolute;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
}

#kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  text-decoration: underline;
  border-radius: 500em;
}

#kalakci .calcells td a > span {
  font-weight: normal;
  font-size: 0.75rem;
  position: absolute;
  top: -.9em;
  right: -.4em;
  width: 1.6em;
  height: 1.6em;
  line-height: 1.7em;
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
  border-radius: 500em;
}

@media screen and (min-width: 451px) {
  #kalakci .calcells td {
    height: 2.5em;
  }
  #kalakci .calcells td::before {
    width: 2.5em;
    height: 2.5em;
    margin: -1.25em 0 0 -1.25em;
  }
  #kalakci .calcells td a {
    line-height: 2.5em;
    width: 2.5em;
    margin-left: -1.25em;
  }
  #kalakci .calcells td a > span {
    right: -.7em;
  }
}

@media screen and (min-width: 601px) {
  #kalakci .calcells {
    font-size: 1rem;
  }
  #kalakci .calcells td, #kalakci .calcells td a {
    font-size: 1rem;
  }
  #kalakci .calcells td a > span {
    font-size: 0.875rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #kalakci .calcells td {
    line-height: 2em;
  }
  #kalakci .calcells td::before {
    margin-top: 0;
    top: 0;
  }
  #kalakci .calcells td a {
    bottom: auto;
  }
}

.akce-podle-data {
  clear: both;
}

#stranka #kalakci {
  margin-top: 10px;
  margin-bottom: 25px;
  max-width: 32em;
  float: left;
}

#stranka #kalakci .c-arrow {
  display: inline-block;
}

#stranka #kalakci .c-arrow:hover {
  text-decoration: underline;
}

#stranka #kalakci .c-before-month, #stranka #kalakci .c-next-month {
  padding: 0;
}

#stranka #kalakci .c-before-month .space, #stranka #kalakci .c-before-month .c-moth, #stranka #kalakci .c-before-month .c-year, #stranka #kalakci .c-next-month .space, #stranka #kalakci .c-next-month .c-moth, #stranka #kalakci .c-next-month .c-year {
  display: none;
}

#stranka #kalakci .c-actual-month {
  padding-left: 0;
  text-align: center;
}

#stranka #kalakci .mesice {
  margin: 0 auto;
  display: table;
  width: 100%;
  line-height: .8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
}

#stranka #kalakci .mesice .c-actual-month {
  display: table-cell;
  width: 71%;
  vertical-align: bottom;
}

#stranka #kalakci .mesice + p {
  margin: 20px 0;
}

#stranka #kalakci .mesice a {
  display: table-cell;
  vertical-align: bottom;
  text-decoration: underline;
  color: #232323;
  text-align: center;
}

#stranka #kalakci .mesice a:hover {
  text-decoration: none !important;
}

#stranka #kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px #367b0f solid;
}

#stranka #kalakci .calcells td.wkday, #stranka #kalakci .calcells td.wkday a {
  color: #2a2a2a;
}

#stranka #kalakci .calcells td.curdate::before {
  background-color: #367b0f;
}

#stranka #kalakci .calcells td.curdate, #stranka #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#stranka #kalakci .calcells td.curdate:hover, #stranka #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#stranka #kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px #367b0f solid;
}

#stranka #kalakci .calcells td.notmnth, #stranka #kalakci .calcells td.notmnth a {
  color: #595959;
}

#stranka #kalakci .calcells td.notmnth a > span {
  background-color: #7ec656;
  border: 0px gold solid;
  color: #2a2a2a;
}

#stranka #kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
}

#stranka #kalakci .calcells td a > span {
  background-color: #7ec656;
  border: 0px gold solid;
  color: #2a2a2a;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  z-index: 15;
  overflow: hidden;
  background: #367b0f;
}

@media screen and (min-width: 601px) {
  #obalmenu1 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 188px;
    background: url(images/bgMenu.png) no-repeat center top;
  }
}

@media screen and (min-width: 1001px) {
  .fixed-menu #obalmenu1 {
    border: 0;
    height: 59px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    background: #7ec656;
    text-align: left;
    margin-top: 59px;
    top: -59px;
    padding: 8px 16px 8px;
    z-index: 100;
    transition: margin .75s;
    border-bottom: 1px solid #367b0f;
  }
  .fixed-menu #obalmenu1 #menu1 {
    padding: 0;
  }
  .fixed-menu #obalmenu1 ul {
    display: flex;
    justify-content: center;
    height: 43px;
  }
  .fixed-menu #obalmenu1 li {
    align-self: center;
  }
  .fixed-menu #obalmenu1 li::after {
    color: #367b0f;
  }
  .fixed-menu #obalmenu1 .menupopis {
    background: url(images/sipkaB.png) no-repeat left center;
  }
  .fixed-menu #obalmenu1 li a {
    color: #252525;
  }
  .fixed-menu #obalmenu1 li a:hover {
    border-bottom-color: rgba(0, 0, 0, 0.55);
  }
}

#menu1 {
  padding: 0.5em 0;
}

@media screen and (min-width: 601px) {
  #menu1 {
    padding: 14px 0 1.15em;
  }
}

@media screen and (min-width: 1001px) {
  #menu1 {
    padding: 20px 0 1.15em;
  }
}

#menu1 ul {
  text-align: center;
  width: 100%;
}

#menu1 .akt a {
  font-weight: 800;
}

#menu1 .akt a:hover {
  text-decoration: none;
  border: 0;
}

#menu1 li {
  font-size: 1.125rem;
  line-height: 1.1;
  display: block;
  margin: 0;
  padding: 0.3em 0;
}

#menu1 li div {
  display: inline-block;
}

#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid transparent;
  line-height: 1.2;
  display: block;
}

#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  border-bottom-color: rgba(255, 255, 255, 0.55);
}

#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}

#menu1 .menupopis {
  position: relative;
  top: 2px;
  left: 5px;
  font-size: 18px;
  display: inline-block;
  overflow: hidden;
  width: 19px;
  height: 19px;
  padding-left: 24px;
  box-sizing: border-box;
  background: url(images/sipkaW.png) no-repeat left center;
}

@media screen and (min-width: 601px) {
  #menu1 li {
    font-size: 1.125rem;
    display: inline-block;
    padding: 0.2em 0.8em;
  }
  #menu1 li::after {
    content: "\|";
    position: relative;
    left: .85em;
    display: inline-block;
    color: white;
  }
  #menu1 li a,
  #menu1 li a:link,
  #menu1 li a:visited {
    line-height: 1.35;
    display: inline;
  }
  #menu1 li:last-child::after {
    display: none;
  }
}

@media screen and (min-width: 801px) {
  #menu1 li {
    padding: 0.2em 1em;
    font-size: 1.125rem;
  }
  #menu1 li::after {
    left: 1.1em;
  }
}

@media screen and (min-width: 1601px) {
  #menu1 li {
    font-size: 1.25rem;
  }
}

#stranka.noleftpanel #telo {
  max-width: 100%;
}

#stranka.noleftpanel #telo #stred {
  width: 100%;
  float: none;
  clear: both;
  padding: 0 0 44px !important;
}

#stranka.noleftpanel #telo #stred .stredUzsi {
  max-width: 100%;
}

#stranka.noleftpanel #telo #stred #zahlavi h2.cvi {
  margin-bottom: 15px;
  text-align: center;
}

#stranka.noleftpanel #menu-start {
  display: none;
}

#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici {
  position: relative;
  margin-bottom: 44px;
}

#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  padding-bottom: 212px;
}

@media screen and (max-width: 500px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}

@media screen and (min-width: 501px) and (max-width: 799px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 48.75%;
    margin-left: 2.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 31.66667%;
    margin-left: 2.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1200px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 23.125%;
    margin-left: 2.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

#stranka.noleftpanel .dok {
  max-width: 800px;
}

#osnova * {
  font-style: normal;
}

.mapa-stranek-2016 h3.souvisejiciodkazy {
  display: none;
}

.mapa-stranek-2016 .editor {
  margin-top: 30px;
  max-width: 100%;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  padding-bottom: 200px;
  max-width: 360px;
  border-bottom: 46px solid white;
}

@media screen and (max-width: 500px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}

@media screen and (min-width: 501px) and (max-width: 1199px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 48.35%;
    margin-left: 3.3%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1200px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 31.13333%;
    margin-left: 3.3%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (max-width: 500px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    margin: 10px auto;
  }
}

@media screen and (min-width: 501px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a {
  font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  text-decoration: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: -46px;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  color: #252525;
  font-size: 1.1875rem;
  font-weight: normal;
}

@media screen and (min-width: 501px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li a {
    font-size: 1.3125rem;
  }
}

@media screen and (min-width: 1001px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li a {
    font-size: 1.4375rem;
  }
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a:hover {
  text-decoration: underline;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a:hover .mtext {
  background: #ededed;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li .mtext {
  min-height: 46px;
  padding: 8px 16px;
  color: #252525;
  line-height: 1.2;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  display: block;
  background: white;
}

.mapa-stranek-2016 .pataprvku, .mapa-stranek-2016 .popis {
  margin: 30px 0;
  box-sizing: border-box;
  font-size: 17px;
  max-width: 800px;
  width: 100%;
}

.mapa-stranek-2016 .pataprvku {
  padding-top: 30px;
  border-top: 1px solid #dfdfdf;
}

/*************************
* VÝBĚRY MENU
*************************/
/***********************
	*** ROZBALOVACÍ MENU ***
	***********************/
.menu-controls,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
	* MOBILNÍ MENU
	**********************/
#menu {
  background-color: #367b0f;
  padding: 20px;
}

#menu > ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}

#menu > ul a,
#menu > ul a:link,
#menu > ul a:visited {
  color: #ffffff;
}

#menu > ul #osmakt,
#menu > ul #osmakt:link,
#menu > ul #osmakt:visited,
#menu > ul #osmakt:hover,
#menu > ul #osmakt:focus,
#menu > ul #osmakt:active {
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
}

#menu ul {
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  #menu-start {
    display: block;
    clear: both;
    text-align: center;
  }
  #menu-start a {
    background: #367b0f;
    display: block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -.15em .75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
  #menu-start a:link,
  #menu-start a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  .no-scroll {
    height: 100%;
    touch-action: none;
    overflow: hidden;
    pointer-events: none;
    -webkit-overflow-scrolling: auto;
  }
  .no-scroll body {
    height: 100%;
    touch-action: none;
    overflow: hidden;
  }
  #menu.menu-initialised {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 200;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 70px 20px 20px 0;
    transition: transform .3s ease-out;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: -webkit-transform .3s ease-out;
  }
  [data-env="Safari"] #menu.menu-initialised {
    max-width: 100%;
  }
  #menu.menu-initialised.menu-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  #menu.menu-initialised > ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
  }
  #menu.menu-initialised > ul > li {
    margin-bottom: 15px;
  }
  #menu.menu-initialised > ul > li:last-child {
    margin-bottom: 80px;
  }
  #menu.menu-initialised ul {
    list-style-type: none;
  }
  #menu.menu-initialised .menu-controls {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    z-index: 100;
    background-color: #7ec656;
    color: #202020;
  }
  #menu.menu-initialised .menu-controls .menu-control-back,
  #menu.menu-initialised .menu-controls .menu-control-title {
    display: block;
  }
  #menu.menu-initialised .menu-controls .menu-control-back {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23202020' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 22px;
  }
  #menu.menu-initialised .menu-controls .menu-control-title {
    padding: 0 20px;
    line-height: 2.2;
    border-right: 70px transparent solid;
    white-space: nowrap;
    font-size: 1.25rem;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #menu.menu-initialised .akt a {
    text-decoration: none;
    font-weight: bold;
  }
  #menu.menu-initialised .menu-indicator {
    display: none;
  }
}

/***********************
	* DESKTOPOVÉ MENU
	***********************/
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  .nadmenu a {
    margin: 64px 0 0;
    position: relative;
    color: #ffffff;
    font-size: 1.375rem;
    padding: 0 16px 0 32px;
    display: block;
    text-decoration: none;
    font-weight: 700;
    font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
    line-height: 1.15;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 801px) and (min-width: 1001px) {
  .nadmenu a {
    padding-left: 46px;
    font-size: 1.5625rem;
  }
}

@media screen and (min-width: 801px) and (min-width: 1201px) {
  .nadmenu a {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 801px) {
  .nadmenu a:hover {
    text-decoration: underline;
  }
  .nadmenu a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -11px;
    display: inline-block;
    width: 13px;
    height: 22px;
    background: url(images/sipkaZpet.png) no-repeat 0 0;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    background-color: #367b0f;
    padding: 0;
  }
  #menu h2.cvi {
    background-color: #228be6;
    padding: 10px 25px 10px 40px;
    font-size: 1.75rem;
    color: #000000;
    font-weight: normal;
  }
  #menu .menu-item-collapsed > ul {
    display: none;
  }
  #menu .menu-item-expanded > a .menu-indicator {
    transform: rotate(225deg);
  }
  #menu .menu-item-expanded > ul {
    display: block;
  }
  #menu .menu {
    font-size: 1.1875rem;
    font-family: "Mukta Vaani", Arial, Helvetica, sans-serif;
    line-height: 1.2;
    padding: 18px 16px 5px 32px;
    font-weight: 300;
  }
}

@media screen and (min-width: 801px) and (min-width: 1001px) {
  #menu .menu {
    padding: 18px 16px 5px 46px;
    font-size: 1.3125rem;
  }
}

@media screen and (min-width: 801px) and (min-width: 1201px) {
  #menu .menu {
    font-size: 1.4375rem;
  }
}

@media screen and (min-width: 801px) {
  #menu .menu ul {
    text-transform: none;
  }
  #menu .menu a,
  #menu .menu a:link,
  #menu .menu a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  #menu .menu a:hover,
  #menu .menu a:link:hover,
  #menu .menu a:visited:hover {
    text-decoration: underline;
  }
  #menu .menu ul a:hover,
  #menu .menu ul a:focus,
  #menu .menu ul a:active {
    text-decoration: underline;
  }
  #menu .menu #osmakt,
  #menu .menu #osmakt:link,
  #menu .menu #osmakt:visited,
  #menu .menu #osmakt:hover,
  #menu .menu #osmakt:focus,
  #menu .menu #osmakt:active {
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    border: 0;
  }
  #menu .menu a {
    padding: 5px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
  #menu .menu .akt a {
    font-weight: bold;
    text-decoration: underline;
  }
  #menu .menu .akt a:hover {
    text-decoration: underline;
  }
  #menu .menu .menu-indicator {
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    top: .85em;
    left: -27px;
    z-index: 10;
    -webkit-transition: -webkit-transform .25s;
    transition: -ms-transform .25s;
    transition: transform .25s;
  }
  #menu .menu .menu-indicator img {
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  #menu .menu ul {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 0 0 -40px;
    padding: 2px 0 12px 0;
  }
  #menu .menu ul a {
    padding: 1px 15px 0 40px;
    line-height: 1.5;
  }
  #menu .menu ul .menu-indicator {
    left: 13px;
    top: .23em;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 0 0 0 24px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li li {
    border: 0;
    font-weight: normal;
  }
}

#google_translate_element {
  display: inline-block;
  position: absolute;
  top: 9px;
  left: 0;
  z-index: 2;
}

#google_translate_element.pole {
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple,
#google_translate_element .goog-te-gadget-simple * {
  font-size: 1rem !important;
}

#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  border: 3px solid white;
  border-radius: 2px;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(1) {
  padding: 0 3px;
  display: inline-block;
  color: #101010;
  position: relative;
  top: 2px;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(1):hover {
  text-decoration: underline;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(3) {
  display: none;
  color: #000000 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] img {
  display: none;
}

@media screen and (max-width: 600px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
}
