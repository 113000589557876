
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/

%card {
   @include grid(500px 2 20px, 1024px 3);
   box-sizing: border-box;
   padding: 20px;
   margin-top: 0;
   margin-bottom: 20px;
   overflow: hidden;
   background-color: lightgrey;

   img {
      float: none;
      display: table;
      margin: 0;
      width: 100%;
   }

   .dok-datum {
      margin-top: 10px;
   }

   .dok-upoutavka {
      display: block;
      overflow: hidden;
      margin: 0;
      
      img {
         margin-bottom: 10px;
      }
   }

   .dok-nazev {
      display: block;
   }

   div {
      margin-top: 8px;
   }
}
