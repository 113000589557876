
/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

@include selection {
	background-color: $color-main;
	color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

img {
	color: inherit;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
}

#hlobsah, #navigace, #navigace2{
	display: block;
}
 
#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;

	#stranka &{
		@include bp("> 800px") {
			margin-bottom: 48px;
		}
	}
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

.stredUzsi{
	width: 100%;
	max-width: 750px;
	font-size: rem(17px);
	
	@include bp("> 800px"){
		float: left;
	}
}

#titul {
	#telo{
		margin: 55px auto 44px;
		display: block;
	}

	#vlevo{
		position: relative;
		max-width: 748px;
		margin: 0 auto;
		width: 100%;
		padding-bottom: 36px;
		
		@include bp("> 500px"){
			padding-bottom: 700px;
		}

		@include bp("> 600px"){
			padding-bottom: 138%;
		}
	
		@include bp("> 800px"){
			padding-bottom: 1020px;
		}
	}	

	@include bp("> 1000px"){
		#telo{
			margin: 77px auto 66px;
		}
	
		#vlevo{
			width: ratio(1535px, 748px);
			padding-bottom: 64%;
			float: left;
		} 
	
		#vpravo{
			width: ratio(1535px, 748px);
			float: right;
		}
	}	
}

#stranka {
	#obalcelek{
		max-width: 1920px;
		margin: 0 auto;
	}

	#stred{
		padding-bottom: 40px;
	}

	@include bp("> 800px") {
        #obalcelek{
            overflow: hidden;
        }
		#stred {
			padding: 15px 0 15px 6%;
		}	
	}
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

#stranka{
	@include bp("> 800px") {
		#telo {
			display: flex;
			justify-content: space-between;
		}
	
		#stred {
			width: ratio($max-width, 1055px);
			order: 1;
			background-color: #ffffff;
		}
	
		#vlevo {
			width: ratio($max-width, 480px);
			position: relative;
			background: $barD;
	
			&::after{
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 100%;
				//width: 200px;
				width: 46px;	
				background: $barD;	
			}
		}
	}
}

/************************
* PRVKY STRÁNKY
************************/

.cestaObal{
	background-color: #ededed;
	min-height: 71px;
	display: flex;
	width: 100%;
	margin: 0 auto;
	max-width: 1920px;
}

.cesta {
	font-size: rem(16px);
	padding: 8px 0 8px 0;
	position: relative;
	color: #595959;
	align-self: center;
	text-align: right;

	a {
		color: #595959;
	}

	strong {
		display: none;
		text-transform: uppercase;
		font-weight: normal;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.dalsi {
	font-size: rem(18px);
	margin: 15px 0 0 0; 
	padding: 5px 0 0 0;
	text-align: center;
	font-family: $font;

	a {
		display: block;
		padding: 10px 30px;
		background-color: blueviolet;
	}

	@include link {
		color: #ffffff;
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	} 

	@include bp("> 800px") {	 
		text-align: right;

		a {
			display: inline-block;
		}
	}
}

.nadpatou2{
	background-color: #ededed;
	padding: 0 0 40px;

	h2.cvi{
		color: $barD;
		font-size: rem(30px);
		font-weight: bold;
		text-transform: uppercase;
		padding: 18px 0;
		margin-top: 24px;

		&::after{
			content: "";
			width: 60px;
			height: 60px;
			background-color: $barD;
			background-repeat: no-repeat;
			background-position: center center;
			border-radius: 60px;
			margin-left: 7%;
			display: inline-block;
   			vertical-align: middle;
		}
	}

	@include bp("< 500px"){
		h2.cvi{
			font-size: rem(25px);
			
			&::after{
				margin-left: 3%;
				width: 52px;
				height: 52px;
			}
		}
	}

	@include bp("> 900px", "<= 1200px"){
		h2.cvi{
			font-size: rem(25px);

			&::after{
				margin-left: 3%;
				width: 52px;
				height: 52px;
			}
		}
	}
	
	@include bp("> 1200px"){
		padding: 16px 0 54px;	
	}

	.mapa-slany h2.cvi::after{
		background-image: url(images/mapa_ico.png);
	}

	#napiste h2.cvi::after{
		background-image: url(images/napiste.png);
	}

	#abo h2.cvi::after{
		background-image: url(images/abo.png);
	}

	.boxNp2{
		@include grid(901px 3 3%);

		@include bp("<= 900px"){
			float: none;
			max-width: 460px;
			margin: 0 auto;
		}
	}

	.poradna{
		p{
			color: #595959;
			font-size: rem(17px);
			max-width: 280px;
			margin: 0 0 18px;
		}

		a{
			display: inline-block;
			width: 178px;
			box-sizing: border-box;
			padding: 13px 0;
			font-size: rem(17px);
			color: #101010;
			background: $barL;
			text-align: center;
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}
	}
}

/************************
* ZÁPATÍ
************************/

#nadpatou {
	background: $barD;
	padding: 20px 0 40px;

	&,
	h2.cvi,
	h3.cvi,
	#{selector-link()} {
		color: #ffffff;
	}

	h2.cvi{
		font-size: rem(28px);
		color: white;
		text-transform: uppercase;
		padding: 0 0 10px;
		margin: 40px 0 0;
	}

	@include bp("> 600px"){
		.boxNp{
			float: left;
			width: 47%;
			padding-right: 3%;
			box-sizing: border-box;
		}
	}

	@include bp("> 1000px"){
		padding: 60px 0; 

		h2.cvi{
			margin: 0;
			padding: 0 0 20px;
		}

		.kontaktNp{
			float: left;
			width: ratio(1535px, 665px);
		}

		.boxNp{
			float: left;
			width: ratio(1535px, 435px);
			padding: 0 16px 0 2.5%;
		}	
	}
}


#obalpata {
	background-color: $footer-background;
	min-width: 320px;
	font-family: $sans-serif;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $footer-text; 
	box-sizing: border-box;
	font-size: rem(14px);

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $footer-link;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis {
		display: none;
	}
}

#nahoru {
	@include transition(opacity .3s);
	position: fixed;
	left: 13px;
	bottom: 40px;
	z-index: 50;
	opacity: 0;
}

// upoutávky pro kalendář akcí na podstránce
#kalendarAkci.obsah{
	.dok li.u{
		margin: 0;
		padding: 16px 0;

		strong .upoutavka{
			max-width: 180px;
			max-height: 135px;
			overflow: hidden;
			display: block;
			float: left;
			padding-right: 20px;

			img{
				max-width: 100%;
				margin: 0 0 6px;
			}

			@include bp("> 600px"){
				max-width: 300px;
				max-height: 225px;
			}
		}
	}	
}
span.zodpovida{
	display: none;
}
