/**********************
*	FOTOGALERIE
**********************/

.nahledy {
	li {
		@include grid( 350px 2 2%, 600px 3, 1024px 4 );
	}

	div {
		> a {
			background: #ededed;
			border: 2px transparent solid;
			border-radius: 0px;
			min-height: 200px;
		}
	}

	@include link-over("div > a") {
		background: transparent;
		border-color: $barD;
	}
}


/**********************
*	PŘEHLEDY GALERIÍ
**********************/

.galerie-2016 {
	.ui {
		li {
			@include grid(400px 2 2%, 1024px 3);
		}
	}
}