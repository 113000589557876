
/***********************
*	ZÁLOŽKY
***********************/

.zalozky {
	font-size: rem(20px);
	line-height: 1.1;
	font-family: $font;
	font-weight: normal;

	ul {
		@extend %sf;
		@extend %reset-list;
	}

	li {
		padding: 0;
		margin: 0 .5em .5em 0;
		float: left;
		position: relative;
		display: block;

		&.azalozka {
			background:transparent none;
		}
	}

	a {
		display: block;
		text-decoration: none;
		margin: 0;
		position: relative;
		z-index: 20;
		cursor: pointer;
		padding: if(nth($form-padding, 2) == 0, 10px 20px 12px, 10px nth($form-padding, 2) 12px);
	}

	@include link {
		background-color: $tab-sub-background;
		color: $tab-sub-text;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $tab-sub-selected-background;
			color: $tab-sub-selected-text;
			text-decoration: none;
			cursor: default;
		}
	}
}


#zahlavi2 {
	margin: 20px 0;
}


#zalozkynadpis {
	padding: 15px 0 0 0;
}

.vych_pol {
	font-size: rem(16px);
	text-align: right;
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	text-transform: uppercase;
	
	strong {
		font-weight: normal;
	}
}


a[id*="k0"] {
	height: 0;
	overflow: hidden;
	line-height: 0;
	font-size: 0;
	color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/

.bezjs {
	padding: 20px 0 15px 0;

	ul {
		@extend %reset-list;
	}

	.inline li::before {
		color: #404040;
	}
}

#keskryti {
	@extend %reset;
	display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/

#titul .zalozky {
	font-size: rem(21px);
	line-height: 1.1;
	font-family: $font;
	text-transform: uppercase;

	.inline li::before {
		content: normal;
	}

	li {
		@extend %reset;
	}

	a {
		padding: 15px;
	}

	@include link {
		background-color: $tab-background;
		color: $tab-text;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $tab-selected-background;
			color: $tab-selected-text;
		}
	}
}

@include bp("< 650px") {
	#linkynakotvy {
		display: none;
	}

	#titul .poz {
		h2.cist {
			position: static;
			width: auto;
			height: auto;
		}

		.skryty {
			display: block;
		}
	}
}
